import React from 'react';
import { graphql } from 'gatsby';
import { Cards, SiteMetadata } from '../components';
import { Layout } from '../layouts/Layout';

export default ({ data, location, pageContext: { title, description, series } }) => {
  return (
    <Layout category={series}>
      <SiteMetadata
        title={title}
        description={description || `${series} - streamings that are being offered online for free, or for a small donation fee, during the COVID-19 pandemic`}
        path={location.pathname}
      />

      <Cards nodes={data.items.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query SinglePageQuery($tableName: String!, $category: [String], $series: [String]) {
    items: allAirtable(
      filter: { table: { eq: $tableName },
                data: {
                  tags: { in: $category }
                  series: { in: $series }
                }
              }
      sort: { fields: data___expiry, order: DESC }
    ) {
      nodes {
        data {
          country
          tags
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
          start
          expiry
        }
      }
    }
  }
`;
